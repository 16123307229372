
import { ref, defineComponent } from '@vue/composition-api';

// @ts-ignore
import GoogleIcon from '@/assets/icons/google.svg';
import { IResponseGoogle } from '@/lib/constants';
import { initializeGoogle } from '@/lib/google';

export default defineComponent({
  components: { GoogleIcon },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['login:social'],
  setup() {
    const googleButton = ref<InstanceType<typeof HTMLDivElement> | null>(null);
    return { googleButton };
  },
  head() {
    return {
      script: [
        {
          async: true,
          defer: true,
          src: 'https://accounts.google.com/gsi/client'
        }
      ]
    };
  },
  mounted() {
    const { social } = this.$config;

    initializeGoogle(
      social.google,
      this.handleLogin,
      this.$refs.googleButton as HTMLDivElement
    );
  },
  methods: {
    login() {
      if (this.googleButton) {
        return this.googleButton
          .querySelector<HTMLDivElement>('div[role=button]')
          ?.click();
      }

      this.$store.commit('sso/SHOW_SNACKBAR', {
        message: 'OPS! Ocorreu um erro ao fazer o login!'
      });
    },
    async handleLogin({ credential }: IResponseGoogle) {
      await this.$emit('login:social', credential);
    }
  }
});
