import { IResponseGoogle } from './constants';

// @ts-ignore
import { onLoad } from '@petlove/webstore-plugins/helpers/onLoad';

const setGoogleCredentials = (
  key: string,
  callback: ({ credential }: IResponseGoogle) => void
) => {
  window.google.accounts.id.initialize({
    callback,
    client_id: key
  });
};

const renderGoogleButton = async (
  wrapper: HTMLDivElement | null | undefined
) => {
  if (!wrapper) return;
  await window.google.accounts.id.renderButton(wrapper, {
    type: 'standard'
  });

  // This is necessary because the google button is render in different way on Chrome/Edge and Firefox/Safari
  const isIframe =
    (wrapper.firstChild?.lastChild as HTMLDivElement).tagName === 'IFRAME';

  isIframe
    ? (wrapper.style.display = 'none')
    : wrapper.classList.add('google--alternative');
};

export const initializeGoogle = (
  key: string,
  callback: ({ credential }: IResponseGoogle) => void,
  wrapper: HTMLDivElement | null | undefined
) => {
  onLoad(() => {
    if (!window.google) return;
    setGoogleCredentials(key, callback);
    renderGoogleButton(wrapper);
  });
};
